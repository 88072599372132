import React from 'react'
import { DonationForm } from '../cmps'

export const Donations = () => {
   return (
      <main className='main'>
         <h1>donations</h1>
         <DonationForm />
         {/* <button id="checkout_button">Checkout Now</button> */}
      </main>
   )
}
